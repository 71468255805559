.evidence-info-label {
    font-family: sans-serif;
    padding: 0 5px;
}

.evidence-info-label h4 {
    color: black;
    font-size: large;
    font-weight: bold;
}

.evidence-info-label h4 {
    color: black;
    font-size: medium;
}