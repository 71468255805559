.evidence-item {
    height: fit-content;
    border-radius: 10px;
    background-color: #f7f7f7;
    border: 1px solid #dfdfdf;
    box-shadow: 5px 5px 5px rgb(216, 216, 216);
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.evidence-image {
    min-height: 200px;
    max-height: 280px;
    border-radius: 10px;
}