.evidence-status {
    background-color: #909090;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin: 10px 0;
    width: fit-content;
}

.evidence-status-reproved {
    background-color: #E50000;
    color: white;
}

.evidence-status-waiting {
    background-color: #909090;
    color: white;
}

.evidence-status-approved {
    background-color: #00B723;
    color: white;
}