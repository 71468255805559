.evidence-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    grid-row-gap: 15px;
    grid-column-gap: 10px;
    margin-bottom: 50px;
}

.btn-group-container {
    padding: 20px 0 20px 0;
    display: flex;
    border: 2px solid green;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
}

.evidence-grid Button {
    margin: 0 5px;
}


@media only screen and (max-width: 1024px) {
    .evidence-grid {
        grid-template-columns: repeat(2, minmax(300px, 1fr));
    }
}


@media only screen and (max-width: 780px) {
    .evidence-grid {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }
}