.my-modal {
    width: 90vw;
    min-width: 90vw;
}

.my-modal img {
    max-width: 80vw;
    border-radius: 10px;
    border: 1px solid lightgray;
    box-shadow: 5px 5px 5px rgb(216, 216, 216);
}

.my-modal .modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.my-modal .modal-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.my-modal .modal-header .modal-title {
    flex: 10;
    text-align: center;
}

.my-modal form {
    width: 80vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.button-container {
    width: 80vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.button-container button {
    padding: 10px;
    margin: 5px;
    min-width: 50%;
}

@media (min-width: 576px) {
    .my-modal {
        width: 90vw;
        min-width: 90vw;
    }
}